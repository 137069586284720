import React, { Suspense, lazy, memo } from 'react';
import { Switch, Route } from 'react-router-dom';

import { useHasRole } from 'views/auth/hooks/useAuth';

import NewClient from 'views/clients/NewClient';
import PageNotFound from './PageNotFound';

const NotificationsLayout = lazy(() =>
  import('modules/notifications/NotificationsPage')
);
const ClientView = lazy(() => import('views/clients/ClientView'));
const BankLayout = lazy(() => import('modules/bank/BankLayout'));
const NotesLayout = lazy(() => import('modules/notes/NotesLayout'));
const CalculatorLayout = lazy(() =>
  import('modules/calculators/CalculatorLayout')
);
const SettingsLayout = lazy(() =>
  import('modules/settings/SettingsLayout')
);
const PermissionsLayout = lazy(() => import('views/permissions/Layout'));

const ProtectedRoute = ({ role, ...props }) => {
  const hasRole = useHasRole(role);

  return hasRole ? <Route {...props} /> : null;
};

const AppContent = () => {
  return (
    <Suspense fallback={<div>טוען...</div>}>
      <Switch>
        <ProtectedRoute role="VIEWER" exact path="/">
          <NotificationsLayout />
        </ProtectedRoute>
        <ProtectedRoute role="ADMIN" exact path="/clients/new">
          <NewClient />
        </ProtectedRoute>
        <ProtectedRoute role="ADMIN" path="/clients/:id">
          <ClientView />
        </ProtectedRoute>
        <ProtectedRoute role="ADMIN" path="/bank">
          <BankLayout />
        </ProtectedRoute>
        <ProtectedRoute role="CALCULATOR" path="/calculators">
          <CalculatorLayout />
        </ProtectedRoute>
        <ProtectedRoute role="NOTES" exact path="/notes">
          <NotesLayout />
        </ProtectedRoute>
        <ProtectedRoute role="ADMIN" path="/settings">
          <SettingsLayout />
        </ProtectedRoute>
        {/* <ProtectedRoute role="OWNER" exact path="/users/:userId?">
          <PermissionsLayout />
        </ProtectedRoute> */}
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default memo(AppContent);
