import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { SETTINGS_QUERY } from '../gql/queries';
import { useCallback, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';

const useTrashStyles = makeStyles((theme) => ({
  redTrash: {
    //color: theme.palette.error.main,
  },
}));

const ClientTrash = ({ style, onDelete, customDeleteButton }) => {
  const { loading, data } = useQuery(SETTINGS_QUERY);
  const CHECK_GLOBAL_PASSWORD_PASSWORD = data?.settings?.globalDeleteKey || null;
  const [openFirstDialog, setOpenFirstDialog] = useState(false);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const classes = useTrashStyles();
  const handleFirstDialogOpen = useCallback(() => {
    setOpenFirstDialog(true);
  }, []);

  const handleFirstDialogClose = useCallback(() => {
    setOpenFirstDialog(false);
  }, []);

  const handleSecondDialogOpen = useCallback(() => {
    setOpenFirstDialog(false);
    setOpenSecondDialog(true);
  }, []);

  const handleSecondDialogClose = useCallback(() => {
    setOpenSecondDialog(false);
    setPassword('');
    setError('');
  }, []);

  const handlePasswordChange = useCallback((event) => {
    setPassword(event.target.value);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    if (CHECK_GLOBAL_PASSWORD_PASSWORD !== null && password === CHECK_GLOBAL_PASSWORD_PASSWORD) {
      if (typeof onDelete === 'function') {
        onDelete();
      } else {
        console.log('onDelete is not implemented');
      }
      handleSecondDialogClose();
    } else {
      setError('הסיסמה אינה נכונה');
    }
  }, [password, onDelete]);

  return (
    <>
      {typeof customDeleteButton === 'function' ? customDeleteButton({ onClick: handleFirstDialogOpen }) :
        <IconButton style={style} onClick={handleFirstDialogOpen} size="small">
          <DeleteIcon className={classes.redTrash} />
        </IconButton>}
      {/* First Dialog - Are you sure? */}
      <Dialog open={openFirstDialog} onClose={handleFirstDialogClose}>
        <DialogTitle>האם אתה בטוח?</DialogTitle>
        <DialogContent>
          <DialogContentText>מחיקה היא פעולה בלתי הפיכה. האם אתה בטוח שברצונך להמשיך?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFirstDialogClose} color="primary">
            ביטול
          </Button>
          <Button onClick={handleSecondDialogOpen} color="secondary">
            כן
          </Button>
        </DialogActions>
      </Dialog>

      {/* Second Dialog - Are you really sure? with Password */}
      <Dialog open={openSecondDialog} onClose={handleSecondDialogClose}>
        <DialogTitle>האם אתה באמת בטוח?</DialogTitle>
        <DialogContent>
          <DialogContentText>הכנס סיסמה לאישור מחיקה.</DialogContentText>
          <style>
            {`
              @font-face {
                font-family: 'PasswordMask';
                src: url('/password.ttf') format('truetype');
              }
            `}
          </style>
          <TextField
            autoFocus
            margin="dense"
            label="סיסמה"
            type="text" // Change to text
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            error={!!error}
            helperText={error}
            autoComplete="new-password"
            inputProps={{
              style: {
                WebkitTextSecurity: 'disc', // For WebKit browsers (Chrome, Safari)
                textSecurity: 'disc', // Experimental, mostly unsupported
                fontFamily: 'PasswordMask', // Apply the custom font you loaded
              },
              readOnly: true,
              onFocus: (e) => e.target.removeAttribute('readonly'),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSecondDialogClose} color="primary">
            ביטול
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            כן, אני בטוח
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClientTrash;